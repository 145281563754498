import React, { useEffect } from 'react';

const Index = () => {

    useEffect(() => {
        document.getElementById('video').play();
    });

    return (
        <>
            <div className="video-background">
                <video id="video" loop muted autoPlay className="video">
                    <source src="video.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="overlay">
                <div className="all-seeing"></div>
            </div>
        </>
    );
};

export default Index;
